<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-card>
        <v-card-text class="px-0 py-0">
          <h2 class="pa-4">Ирцийн мэдээлэл</h2>
        </v-card-text>
        <v-card-text v-if="attendances">
          <v-data-table
            hide-details
            hide-default-footer
            :items-per-page="-1"
            :items="attendances"
            :headers="[
              {
                text: 'No',
                align: 'end',
                value: 'index',
                sortable: true,
                width: '1%',
                fixed: true,
              },
            ]"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td style="width: 10%">
                  2024-{{ props.item.month }}-{{ props.item.day }}
                </td>
                <td v-if="props.item._details">

                  <span v-if="_getCheckFoodHave(props.item)">
                    <v-btn>kdfjsjdrf</v-btn>
                  </span>

                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import { sync } from "vuex-pathify";
// const fb = require("@/firebaseConfig.js");
export default {
  name: "light-table-transparent",
  computed: {
    ...sync("*"),
  },
  components: {},
  data() {
    return {
      attendances: null,
      selectedClassGroup: null,
    };
  },

  created() {
    console.log(this.userData);
    this.userData.school.ref
      .collection("departments-" + this.userData.school.currentYear)
      .doc("department-" + this.userData.ACADEMIC_LEVEL)
      .collection("programs")
      .doc(this.userData.STUDENT_GROUP_ID.toString())
      .get()
      .then((doc) => {
        this.selectedClassGroup = doc.data();
        this.selectedClassGroup.id = doc.id;
        this.selectedClassGroup.ref = doc.ref;
        console.log(this.selectedClassGroup.ref.path, "selectedClassGroup");

        this.selectedClassGroup.ref
          .collection("attendances-" + this.userData.school.currentYear)
          // .where("month", "==", 9)
          // .where("month", "==", 9)
          // .where("closed", "==", true)
          //  .orderBy("createdAt")
          .get()
          .then((docs) => {
            this.attendances = [];
            docs.forEach((doc) => {
              let att = doc.data();
              att.ref = doc.ref;
              att.id = doc.id;
              att.name = this.selectedClassGroup.STUDENT_GROUP_NAME;
              att.STUDENT_GROUP_ID = this.selectedClassGroup.STUDENT_GROUP_ID;
              att.PROGRAM_STAGE_ID = this.selectedClassGroup.PROGRAM_STAGE_ID;
              att.ACADEMIC_LEVEL = this.selectedClassGroup.ACADEMIC_LEVEL;
              if (att.STUDENT_GROUP_ID == this.userData.STUDENT_GROUP_ID) {
                console.log( "attts", att._details);
                // console.log("att", att._details, att.STUDENT_GROUP_ID,att);
                this.attendances.push(att);
              }
            });
          });
      });
  },
  mounted() {
    // this.userData.school.ref
    //   .collection("lessons")
    //   .where("lessonTypeName", "==", "Сонгон")
    //   .orderBy("name", "asc")
    //   .get()
    //   .then((querySnapshot) => {
    //     this.lessonCategories = [];
    //     querySnapshot.forEach((doc) => {
    //       let lesson = doc.data();
    //       lesson.id = doc.id;
    //       lesson.ref = doc.ref;
    //       lesson.name2 = lesson.name + " - " + lesson.lessonTypeName;
    //       this.lessonCategories.push(lesson);
    //     });
    //   });
  },
  methods: {
    _getCheckFoodHave(attt) {
      var foundStudent = attt._details.find(aa=>aa.studentId == this.userData.PERSON_ID)
      return foundStudent
    }
  },
};
</script>
