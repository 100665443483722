var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"my-6 mx-auto",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('h2',{staticClass:"pa-4"},[_vm._v("Ирцийн мэдээлэл")])]),(_vm.attendances)?_c('v-card-text',[_c('v-data-table',{attrs:{"hide-details":"","hide-default-footer":"","items-per-page":-1,"items":_vm.attendances,"headers":[
            {
              text: 'No',
              align: 'end',
              value: 'index',
              sortable: true,
              width: '1%',
              fixed: true,
            },
          ]},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticStyle:{"width":"10%"}},[_vm._v(" 2024-"+_vm._s(props.item.month)+"-"+_vm._s(props.item.day)+" ")]),(props.item._details)?_c('td',[(_vm._getCheckFoodHave(props.item))?_c('span',[_c('v-btn',[_vm._v("kdfjsjdrf")])],1):_vm._e()]):_vm._e()])]}}],null,false,2818562283)})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }